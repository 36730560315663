import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { Typography, useMediaQuery, useTheme, Container, Box } from "@mui/material";

function CompanyPhilosophyPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ py: isMobile ? 4 : 8 }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{
            mt: isMobile ? 6 : 8,
            mb: 6,
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#B13E81'
          }}
        >
          企業理念
        </Typography>

        <Box sx={{ mb: 6, bgcolor: 'white', p: 4, borderRadius: 2, boxShadow: 3 }}>
          <Typography
            component="h2"
            variant="h5"
            sx={{
              mb: 3,
              fontWeight: 'bold',
              color: '#B13E81'
            }}
          >
            社是
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{
              fontSize: '1.25rem',
              fontStyle: 'italic',
              color: '#7A4552',
              textAlign: 'center'
            }}
          >
            「変化を続け誠実にマチと仲間を幸せにします」
          </Typography>
        </Box>

        <Box sx={{ mb: 6, bgcolor: 'white', p: 4, borderRadius: 2, boxShadow: 3 }}>
          <Typography
            component="h2"
            variant="h5"
            sx={{
              mb: 3,
              fontWeight: 'bold',
              color: '#B13E81'
            }}
          >
            経営理念
          </Typography>
          <ul style={{ listStyleType: 'disc', paddingLeft: '1.5rem' }}>
            {[
              'お客様からの安心と信用を第一に考え、鮮度管理を徹底致します。',
              '一人一人のお客様を大切にし、心を込めた丁寧な接客をします。',
              '地域の方々-お客様が心地の良い清潔な環境を提供します。',
              'お客様の要望に常に耳を傾け、期待に応えるお店にします。',
              'たくさんの"ありがとう"と笑顔が集まるお店にします。'
            ].map((item, index) => (
              <li key={index}>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ color: '#7A4552', lineHeight: 1.8 }}
                >
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default CompanyPhilosophyPage